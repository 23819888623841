import PropTypes from "prop-types";

function IconChevronUp({ fill, size, ...rest }) {
    return (
        <svg
            viewBox="0 0 64 64"
            width={size}
            height={size}
            fill={fill}
            {...rest}
        >
            <path d="M33.73 24.06 48.58 38.91 44.33 43.15 29.48 28.3 14.63 43.15 10.39 38.91 29.48 19.82 33.73 24.06z" />
        </svg>
    );
}

IconChevronUp.propTypes = {
    fill: PropTypes.string,
    size: PropTypes.number,
    style: PropTypes.shape({ verticalAlign: PropTypes.string })
};

IconChevronUp.defaultProps = {
    fill: "var(--color-black)",
    size: 48,
    style: { verticalAlign: "middle" }
};

export default IconChevronUp;
