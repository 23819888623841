import styled, { css } from "styled-components";

export const Container = styled.footer`
    height: ${({ open }) => (open ? "100%" : "45px")};
    overflow: hidden;

    background-color: var(--color-primary);
    transform-origin: top;

    transition: height 0.3s var(--animation-easing-decelerate);
`;

export const BlobContainer = styled.div`
    position: relative;

    display: flex;
    justify-content: flex-end;
    height: 33px;

    background-color: var(--color-white);
`;

export const BlobImage = styled.img`
    width: 124px;
    height: 38px;
`;

export const IconWrapper = styled.div`
    position: absolute;
    top: 5px;
    right: 51px;

    transform: rotate(180deg);

    transition: transform 250ms linear;

    ${({ open }) =>
        open &&
        css`
            right: 49px;

            transform: rotate(0deg);
        `}
`;
