import PropTypes from "prop-types";

import Head from "components/shared/head/Head";
import Header from "components/shared/pageLayout/header/Header";
import Footer from "components/shared/pageLayout/footer/Footer";

import { Layout } from "./PageLayout.styled";

function PageLayout({
    siteName,
    title,
    description,
    keywords,
    canonical,
    children,
    forceMobileHeader,
    showOrderButton
}) {
    return (
        <Layout>
            <Head
                siteName={siteName}
                title={title}
                description={description}
                keywords={keywords}
                canonical={canonical}
            />
            <Header
                forceMobileView={forceMobileHeader}
                showOrderButton={showOrderButton}
            />
            {children}
            <Footer />
        </Layout>
    );
}

PageLayout.propTypes = {
    siteName: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    canonical: PropTypes.string,
    keywords: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.node.isRequired,
    forceMobileHeader: PropTypes.bool,
    showOrderButton: PropTypes.bool
};

PageLayout.defaultProps = {
    siteName: "Nationale Diner Cadeaukaart",
    title: "Nationale Diner Cadeaukaart | De restaurantbon van Nederland!",
    description:
        "De Nationale Diner Cadeaukaart is de leukste restaurantbon van Nederland. Keuze uit vele toprestaurants!",
    keywords: ["cadeaukaart", "diner", "restaurant", "uiteten", "nationaal"],
    canonical: undefined,
    forceMobileHeader: false,
    showOrderButton: true
};

export default PageLayout;
