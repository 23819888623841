import styled from "styled-components";

import media from "constants/media";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 30px 20px;

    border-top: 1px solid var(--color-purple-50);

    ${media.mdUp} {
        flex-direction: row;
        margin-top: 30px;
        padding: 30px 20px 0;

        border-top-color: var(--color-purple-90);
    }

    ${media.lgUp} {
        flex: 1 0 auto;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 25%;
        margin-top: 0;
        padding: 0 10px;

        border-top: none;
    }
`;

export const Logo = styled.img`
    width: 105px;
    margin: 0 0 20px;

    ${media.mdUp} {
        width: 88px;
        height: 50px;
        margin: 0;
    }

    ${media.lgUp} {
        margin: -20px 0 0;
    }
`;

export const Copyright = styled.div`
    margin: 0 0 15px;

    color: var(--color-white);
    font-weight: bold;
    font-size: 12px;
    line-height: 30px;

    ${media.mdUp} {
        margin: 10px 0 5px;

        color: var(--color-black);
        font-weight: normal;
        font-size: 13px;
    }
`;

export const Policy = styled.ul`
    display: flex;
    gap: 0 20px;
    margin: 0;
    padding: 0;

    text-align: center;

    list-style: none;

    ${media.mdUp} {
        text-align: left;
    }

    ${media.lgUp} {
        flex-direction: column;
        gap: 0;
    }
`;

export const PolicyLink = styled.a`
    display: inline-block;
    padding: 5px 0;

    color: var(--color-secondary);
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;

    ${media.mdUp} {
        padding: 10px 0;

        color: var(--color-primary);
        font-size: var(--font-size-body);

        :hover,
        :focus {
            color: var(--color-secondary);
        }
    }
`;
