import styled from "styled-components";

import media from "constants/media";

export const Container = styled.div`
    padding: 30px 10px;

    text-align: center;

    border-bottom: 1px solid rgba(247, 244, 249, 0.2);

    ${media.mdUp} {
        flex: 1 1 auto;
        padding: 0 20px;

        text-align: left;
    }

    ${media.lgUp} {
        max-width: ${(100 / 12) * 4}%;
        padding: 0 10px;
    }
`;

export const Title = styled.h2`
    margin: 0 0 25px;

    color: var(--color-white);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-body);
    line-height: 20px;

    ${media.mdUp} {
        margin-bottom: 20px;

        color: var(--color-black);
    }
`;

export const Method = styled.img`
    & + img {
        margin-left: 20px;
    }
`;
