import PaymentMethods from "../paymentMethods/PaymentMethods";
import Shipment from "../shipment/Shipment";
import Contact from "../contact/Contact";
import Links from "../links/Links";
import Terms from "../terms/Terms";

import { StyledFooter, Container, Row } from "./DesktopFooter.styled";

const navItems = [
    {
        url: "/helpdesk/",
        label: "Veelgestelde Vragen"
    },
    {
        url: "/over-ons/",
        label: "Over ons"
    },
    {
        url: "/dinnerbon/",
        label: "Dinnerbon"
    },
    {
        url: "/dinercheque/",
        label: "Dinercheque"
    },
    {
        url: "/restaurant-cadeaukaart/",
        label: "Restaurant Cadeaukaart"
    },
    {
        url: "/nationale-dinerbon/",
        label: "Nationale Dinerbon"
    },
    {
        url: "/restaurantbon/",
        label: "Restaurantbon"
    },
    {
        url: "/verjaardagscadeau/",
        label: "Verjaardagscadeau"
    },
    {
        url: "/restaurantbon-bestellen/",
        label: "Dinerbon bestellen"
    },
    {
        url: "/restaurantbon-bestellen/",
        label: "Dinerbon"
    },
    {
        url: "https://www.cadeaubon.nl/",
        label: "Andere cadeaubonnen"
    }
];

function FooterDesktop() {
    return (
        <StyledFooter>
            <Row>
                <Container>
                    <Contact />
                    <PaymentMethods />
                    <Shipment />
                </Container>
            </Row>
            <Row>
                <Container>
                    <Links items={navItems} />
                    <Terms />
                </Container>
            </Row>
        </StyledFooter>
    );
}

export default FooterDesktop;
