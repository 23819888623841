import styled from "styled-components";

import media, { sizes } from "constants/media";

export const StyledFooter = styled.footer`
    position: relative;

    width: calc(100vw - 20px);
    margin: 100px 0 0;

    background-color: var(--color-white);
    border-top: 1px solid var(--color-purple-90);
    border-top-right-radius: 65px;

    :after {
        position: absolute;
        bottom: 0;
        left: 20px;

        z-index: -1;

        width: 100%;
        height: calc(100% + 100px);

        background-color: var(--color-purple-90);
        border-radius: 80px 0 0;

        content: "";
    }

    ${media.lgUp} {
        width: calc(50vw + ${sizes.lg}px / 2);

        :after {
            left: calc(50vw - ${sizes.lg}px / 2);
        }
    }

    ${media.xlUp} {
        width: calc(50vw + ${sizes.xl}px / 2);

        :after {
            left: calc(50vw - ${sizes.xl}px / 2);
        }
    }
`;

export const Row = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 30px 0;

    & + & {
        border-top: 1px solid var(--color-purple-90);
    }

    ${media.lgUp} {
        padding: 45px 0;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    ${media.lgUp} {
        justify-content: flex-start;
        width: 100%;
        max-width: var(--container-width);
    }
`;
