import styled from "styled-components";

import media from "constants/media";

export const ShipmentContainer = styled.div`
    display: flex;
    max-width: 295px;
    margin: 0 auto;
    padding: 25px;

    ${media.mdUp} {
        flex-wrap: wrap;
        align-items: center;
        max-width: max-content;
        padding: 0 20px;
    }

    ${media.lgUp} {
        flex: 1 0 auto;
        min-width: 295px;
        max-width: ${(100 / 12) * 3}%;
        padding: 0 10px;
    }
`;

export const Title = styled.h2`
    display: none;
    width: 100%;
    margin: 0 0 20px;

    color: var(--color-black);
    font-size: var(--font-size-body);
    line-height: 20px;

    ${media.mdUp} {
        display: block;
    }
`;

export const Logo = styled.img`
    width: 50px;
    height: 50px;
`;

export const Text = styled.p`
    margin: 0 0 0 25px;

    color: var(--color-white);
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);

    ${media.mdUp} {
        max-width: 170px;
        margin: 0 25px;

        color: var(--color-black);
    }
`;
