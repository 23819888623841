import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";

import FooterMobile from "./mobile/MobileFooter";
import FooterDesktop from "./desktop/DesktopFooter";

function Footer() {
    const { mobileView } = useMediaQueryContext();

    return (
        <>
            {mobileView && <FooterMobile />}
            {!mobileView && <FooterDesktop />}
        </>
    );
}

export default Footer;
