import styled from "styled-components";

import media from "constants/media";

export const Navigation = styled.nav`
    width: 100%;

    ${media.mdUp} {
        padding: 0 10px;
    }

    ${media.lgUp} {
        flex: 1 1 auto;
        width: 75%;
        padding: 0;
    }
`;

export const FooterLinkList = styled.ul`
    display: flex;
    flex-flow: column wrap;
    width: 100%;
    margin: 0;
    padding: 0;

    list-style: none;
    border-bottom: 1px solid var(--color-purple-50);

    ${media.mdUp} {
        height: 160px;

        border-bottom: none;
    }
`;

export const Item = styled.li`
    border-top: 1px solid var(--color-purple-50);

    ${media.mdUp} {
        border-top: none;
    }
`;

export const FooterLink = styled.a`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 5px 15px 10px;

    color: var(--color-white);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-body);
    line-height: 20px;

    &:hover,
    &:focus {
        color: var(--color-secondary);
    }

    ${media.mdUp} {
        padding: 10px;

        color: var(--color-primary);
    }
`;
