import styled from "styled-components";

import media from "constants/media";

export const Container = styled.div`
    margin-bottom: 20px;
    padding: 0 10px;

    ${media.mdUp} {
        width: 100%;
        margin: 0 0 30px;
        padding: 0 20px 30px;

        border-bottom: 1px solid var(--color-purple-90);
    }

    ${media.lgUp} {
        flex: 1 1 auto;
        max-width: ${(100 / 12) * 5}%;
        margin: 0;
        padding: 0 10px;

        border-bottom: none;
    }
`;

export const Title = styled.h2`
    display: none;
    margin: 0 0 20px;

    color: var(--color-black);
    font-size: 19px;
    line-height: 20px;

    ${media.mdUp} {
        display: block;
    }
`;

export const SubTitle = styled.p`
    margin: 0;

    color: var(--color-white);
    font-weight: bold;
    font-size: var(--font-size-body);
    line-height: 20px;

    a {
        color: inherit;
        font-weight: inherit;
    }

    ${media.mdUp} {
        margin: 0 0 10px;

        color: var(--color-black);
    }
`;

export const Text = styled.p`
    margin: 0;

    color: var(--color-white);
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);

    ${media.mdUp} {
        color: var(--color-black);
    }
`;
