import { Container, Title, SubTitle, Text } from "./Contact.styled";

function Contact() {
    return (
        <Container>
            <Title>Contact opnemen</Title>
            <SubTitle>
                Vragen? Bel: <a href="tel:0852086522">085 208 6522</a>
            </SubTitle>
            <Text>Maandag t/m vrijdag: 09:00-17:00</Text>
        </Container>
    );
}

export default Contact;
